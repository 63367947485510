<svg width="16" height="18" viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3850_497)">
    <path
      d="M9.52217 8.13291L15.4785 0.93457H14.0671L8.89516 7.18479L4.76437 0.93457H0L6.24656 10.386L0 17.9346H1.41155L6.87321 11.3341L11.2356 17.9346H16L9.52183 8.13291H9.52217ZM7.58887 10.4693L6.95596 9.52813L1.92015 2.0393H4.0882L8.15216 8.08302L8.78507 9.02417L14.0677 16.8801H11.8997L7.58887 10.4696V10.4693Z"
    />
  </g>
  <defs>
    <clipPath id="clip0_3850_497">
      <rect width="16" height="17" fill="white" transform="translate(0 0.93457)" />
    </clipPath>
  </defs>
</svg>
