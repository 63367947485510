import Facebook from '@components/SVGs/Facebook.svelte';
import Instagram from '@components/SVGs/Instagram.svelte';
import LinkedIn from '@components/SVGs/LinkedIn.svelte';
import TikTok from '@components/SVGs/TikTok.svelte';
import X from '@components/SVGs/X.svelte';
import type { SvelteComponent } from 'svelte';

export type SocialLink = {
  link: string;
  icon: typeof SvelteComponent;
  alt?: string;
};

export const SOCIALS: SocialLink[] = [
  {
    link: 'https://www.instagram.com/joinflip/',
    icon: Instagram,
    alt: 'Instagram'
  },
  {
    link: 'https://www.tiktok.com/@joinflip',
    icon: TikTok,
    alt: 'TikTok'
  },
  {
    link: 'https://www.facebook.com/profile.php?id=100063639637560',
    icon: Facebook,
    alt: 'Facebook'
  },
  {
    link: 'https://x.com/joinflip?s=21&t=M8LBvx-IrgGLRdEB01Jp1g',
    icon: X,
    alt: 'X'
  },
  {
    link: 'https://www.linkedin.com/company/joinflip/mycompany/',
    icon: LinkedIn,
    alt: 'LinkedIn'
  }
];
