<script lang="ts">
  import Copyright from '@components/Footer/Copyright/Copyright.svelte';
  import { NAVIGATION_ITEMS } from '@components/Footer/navigation.const';
  import NavigationItem from '@components/Footer/NavigationItem/NavigationItem.svelte';
  import Socials from '@components/Footer/Socials/Socials.svelte';
  import Stores from '@components/Footer/Stores/Stores.svelte';
  import Logo from '@components/SVGs/Logo.svelte';

  export let containerClass = 'content-wrapper';
</script>

<footer class="w-full pt-10 pb-32 bg-black-carbon-main">
  <div class="{containerClass} grid grid-cols-1 lg:grid-cols-3 gap-6">
    <figure class="max-w-[220px]">
      <Logo css="w-full h-auto fill-dark-cream"></Logo>
    </figure>

    <nav class="grid w-full grid-cols-1 gap-x-6 gap-y-6 lg:col-span-2 lg:grid-cols-2 lg:gap-y-16">
      {#each NAVIGATION_ITEMS as item}
        <NavigationItem {item} />
      {/each}
      <div class="flex flex-col order-4 gap-6">
        <Socials />
        <Stores />
      </div>
      <Copyright />
    </nav>
  </div>
</footer>
