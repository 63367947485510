<svg width="116" height="36" viewBox="0 0 116 36" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M111.873 35.3049H4.06344C1.82335 35.3049 0 33.4764 0 31.2381V4.99543C0 2.7562 1.82335 0.93457 4.06344 0.93457H111.873C114.112 0.93457 116 2.7562 116 4.99543V31.2381C116 33.4764 114.112 35.3049 111.873 35.3049Z"
    fill="#F2EEE2"
  />
  <path
    d="M25.8884 17.9337C25.8635 15.1643 28.156 13.817 28.2608 13.7543C26.9625 11.8614 24.9501 11.6027 24.2429 11.5821C22.5527 11.4042 20.9133 12.5934 20.0523 12.5934C19.1741 12.5934 17.8483 11.5993 16.4194 11.6285C14.5805 11.6569 12.8603 12.7215 11.9168 14.3747C9.96975 17.7455 11.4219 22.6991 13.2874 25.4238C14.2205 26.7582 15.3109 28.2481 16.7381 28.1957C18.1344 28.1381 18.656 27.3055 20.341 27.3055C22.0106 27.3055 22.5003 28.1957 23.9559 28.1622C25.4545 28.1381 26.3979 26.8218 27.2984 25.4753C28.3768 23.9458 28.8099 22.4396 28.8271 22.3622C28.7918 22.3502 25.9167 21.253 25.8884 17.9337Z"
    fill="#212121"
  />
  <path
    d="M23.1392 9.78984C23.8902 8.85068 24.404 7.57298 24.2614 6.27637C23.1744 6.32449 21.8151 7.02821 21.0323 7.94675C20.3397 8.75616 19.7211 10.0828 19.8809 11.3305C21.1019 11.4216 22.3556 10.7144 23.1392 9.78984Z"
    fill="#212121"
  />
  <path
    d="M46.0952 28.0045H44.1438L43.0749 24.6457H39.3595L38.3412 28.0045H36.4414L40.1225 16.5696H42.3961L46.0952 28.0045ZM42.7527 23.2365L41.786 20.2506C41.6837 19.9456 41.4921 19.2272 41.2094 18.0965H41.1751C41.0625 18.5828 40.8812 19.3011 40.632 20.2506L39.6825 23.2365H42.7527Z"
    fill="#212121"
  />
  <path
    d="M55.5615 23.7804C55.5615 25.1827 55.1826 26.2911 54.4247 27.1048C53.7459 27.8292 52.903 28.1909 51.8968 28.1909C50.8107 28.1909 50.0305 27.8008 49.5553 27.0206H49.5209V31.3641H47.689V22.4735C47.689 21.5919 47.6658 20.6871 47.6211 19.7591H49.2322L49.3345 21.066H49.3688C49.9798 20.0813 50.9069 19.5898 52.1511 19.5898C53.1238 19.5898 53.9358 19.9739 54.5854 20.743C55.2367 21.5128 55.5615 22.525 55.5615 23.7804ZM53.6952 23.8474C53.6952 23.0449 53.5148 22.3833 53.1521 21.8626C52.756 21.3195 52.2241 21.048 51.5574 21.048C51.1054 21.048 50.6947 21.1992 50.3278 21.4974C49.96 21.7981 49.7194 22.1908 49.6068 22.6771C49.5501 22.904 49.5218 23.0896 49.5218 23.2356V24.6104C49.5218 25.2102 49.7057 25.7163 50.0734 26.1296C50.4412 26.5429 50.9189 26.7491 51.5067 26.7491C52.1966 26.7491 52.7337 26.4827 53.1178 25.9517C53.5027 25.4199 53.6952 24.7187 53.6952 23.8474Z"
    fill="#212121"
  />
  <path
    d="M65.045 23.7804C65.045 25.1827 64.6661 26.2911 63.9074 27.1048C63.2294 27.8292 62.3865 28.1909 61.3803 28.1909C60.2942 28.1909 59.514 27.8008 59.0397 27.0206H59.0053V31.3641H57.1734V22.4735C57.1734 21.5919 57.1502 20.6871 57.1055 19.7591H58.7166L58.8188 21.066H58.8532C59.4633 20.0813 60.3904 19.5898 61.6355 19.5898C62.6073 19.5898 63.4193 19.9739 64.0706 20.743C64.7194 21.5128 65.045 22.525 65.045 23.7804ZM63.1787 23.8474C63.1787 23.0449 62.9974 22.3833 62.6348 21.8626C62.2387 21.3195 61.7085 21.048 61.0409 21.048C60.588 21.048 60.1782 21.1992 59.8104 21.4974C59.4427 21.7981 59.2029 22.1908 59.0904 22.6771C59.0345 22.904 59.0053 23.0896 59.0053 23.2356V24.6104C59.0053 25.2102 59.1892 25.7163 59.5552 26.1296C59.923 26.542 60.4007 26.7491 60.9902 26.7491C61.6802 26.7491 62.2172 26.4827 62.6013 25.9517C62.9862 25.4199 63.1787 24.7187 63.1787 23.8474Z"
    fill="#212121"
  />
  <path
    d="M75.6487 24.7978C75.6487 25.7705 75.311 26.5619 74.6331 27.1728C73.8881 27.8404 72.851 28.1738 71.5183 28.1738C70.2878 28.1738 69.3014 27.9367 68.5547 27.4615L68.9792 25.9346C69.7834 26.421 70.6659 26.665 71.6274 26.665C72.3174 26.665 72.8544 26.5086 73.2402 26.1975C73.6243 25.8865 73.8159 25.4689 73.8159 24.9482C73.8159 24.4842 73.6578 24.0932 73.3408 23.7762C73.0254 23.4591 72.4987 23.1644 71.7632 22.892C69.7611 22.1453 68.7609 21.0515 68.7609 19.6131C68.7609 18.6731 69.1115 17.9024 69.8135 17.3026C70.5129 16.702 71.4461 16.4021 72.613 16.4021C73.6535 16.4021 74.518 16.5834 75.2079 16.9451L74.7499 18.4385C74.1055 18.0879 73.3769 17.9127 72.5614 17.9127C71.917 17.9127 71.4134 18.0716 71.0526 18.3878C70.7475 18.6705 70.5946 19.0151 70.5946 19.4232C70.5946 19.8752 70.769 20.249 71.1196 20.5428C71.4246 20.8143 71.9788 21.1082 72.7831 21.4253C73.767 21.8214 74.4896 22.2845 74.9545 22.8155C75.4176 23.3448 75.6487 24.0073 75.6487 24.7978Z"
    fill="#212121"
  />
  <path
    d="M81.7051 21.1341H79.6858V25.1373C79.6858 26.1556 80.0416 26.6642 80.7548 26.6642C81.0821 26.6642 81.3537 26.6359 81.5685 26.5792L81.6192 27.9703C81.2583 28.1052 80.7831 28.1731 80.1945 28.1731C79.471 28.1731 78.9056 27.9522 78.4975 27.5115C78.0911 27.0698 77.8866 26.3291 77.8866 25.2886V21.1324H76.6836V19.7576H77.8866V18.2479L79.6858 17.7048V19.7576H81.7051V21.1341Z"
    fill="#212121"
  />
  <path
    d="M90.8164 23.814C90.8164 25.0814 90.4538 26.122 89.7303 26.9357C88.9716 27.7735 87.9645 28.1911 86.7092 28.1911C85.4993 28.1911 84.5361 27.7898 83.8177 26.9872C83.0994 26.1847 82.7402 25.1716 82.7402 23.9507C82.7402 22.6729 83.1097 21.6264 83.8513 20.8127C84.5911 19.9981 85.5895 19.5908 86.8449 19.5908C88.0548 19.5908 89.0283 19.9921 89.763 20.7955C90.4658 21.5748 90.8164 22.581 90.8164 23.814ZM88.9157 23.8733C88.9157 23.1129 88.7533 22.4607 88.4242 21.9168C88.0401 21.2586 87.4911 20.9304 86.7796 20.9304C86.0432 20.9304 85.4839 21.2595 85.0998 21.9168C84.7707 22.4616 84.6083 23.1241 84.6083 23.9077C84.6083 24.6681 84.7707 25.3203 85.0998 25.8633C85.4959 26.5215 86.0492 26.8498 86.7633 26.8498C87.4627 26.8498 88.0118 26.5147 88.4079 25.8462C88.7456 25.2919 88.9157 24.6329 88.9157 23.8733Z"
    fill="#212121"
  />
  <path
    d="M96.7708 21.3702C96.5895 21.3367 96.3962 21.3195 96.1934 21.3195C95.5489 21.3195 95.0506 21.5627 94.7 22.0499C94.395 22.4795 94.242 23.0225 94.242 23.6782V28.0045H92.4109L92.4281 22.3558C92.4281 21.4054 92.4049 20.5402 92.3594 19.76H93.955L94.022 21.3376H94.0727C94.2661 20.7954 94.5711 20.3589 94.9887 20.0315C95.3969 19.7368 95.8377 19.5898 96.3128 19.5898C96.4821 19.5898 96.6351 19.6019 96.7708 19.6234V21.3702Z"
    fill="#212121"
  />
  <path
    d="M104.964 23.4915C104.964 23.8198 104.943 24.0965 104.897 24.3224H99.4016C99.4231 25.137 99.6886 25.76 100.199 26.1896C100.662 26.5737 101.261 26.7661 101.997 26.7661C102.81 26.7661 103.553 26.6364 104.22 26.376L104.507 27.6477C103.727 27.988 102.806 28.1573 101.743 28.1573C100.465 28.1573 99.4609 27.7809 98.7306 27.0291C98.0019 26.2772 97.6367 25.2676 97.6367 24.0011C97.6367 22.7577 97.9761 21.7223 98.6558 20.8966C99.3673 20.015 100.329 19.5742 101.539 19.5742C102.727 19.5742 103.627 20.015 104.238 20.8966C104.721 21.5969 104.964 22.463 104.964 23.4915ZM103.218 23.0164C103.23 22.4733 103.11 22.0042 102.862 21.6081C102.545 21.0985 102.058 20.8442 101.402 20.8442C100.803 20.8442 100.316 21.0925 99.9438 21.5909C99.6388 21.987 99.4575 22.4622 99.4016 23.0155L103.218 23.0164Z"
    fill="#212121"
  />
  <path
    d="M42.147 9.53519C42.147 10.5465 41.8436 11.3078 41.2379 11.8191C40.6768 12.2908 39.8794 12.5271 38.8465 12.5271C38.3344 12.5271 37.8962 12.5048 37.5293 12.4601V6.93424C38.0079 6.85691 38.5235 6.81738 39.0803 6.81738C40.0641 6.81738 40.8057 7.03134 41.3057 7.45924C41.866 7.943 42.147 8.6347 42.147 9.53519ZM41.1975 9.56011C41.1975 8.9045 41.0239 8.40184 40.6768 8.05127C40.3296 7.70155 39.8227 7.52626 39.155 7.52626C38.8715 7.52626 38.63 7.54517 38.4298 7.58469V11.7856C38.5406 11.8028 38.7434 11.8105 39.0382 11.8105C39.7273 11.8105 40.2592 11.6189 40.6338 11.2356C41.0084 10.8524 41.1975 10.2939 41.1975 9.56011Z"
    fill="#212121"
  />
  <path
    d="M47.182 10.4185C47.182 11.0414 47.0042 11.5518 46.6484 11.9522C46.2755 12.3638 45.7815 12.5692 45.1645 12.5692C44.5699 12.5692 44.0964 12.3724 43.7433 11.9771C43.391 11.5828 43.2148 11.0852 43.2148 10.4855C43.2148 9.85824 43.3961 9.34355 43.7605 8.94399C44.1248 8.54444 44.6146 8.34424 45.2315 8.34424C45.8261 8.34424 46.3039 8.54101 46.6656 8.9354C47.0093 9.31863 47.182 9.81356 47.182 10.4185ZM46.248 10.4477C46.248 10.0739 46.1673 9.75341 46.0066 9.48618C45.8175 9.1631 45.5486 9.00156 45.1989 9.00156C44.8371 9.00156 44.5622 9.1631 44.3731 9.48618C44.2116 9.75341 44.1317 10.0791 44.1317 10.464C44.1317 10.8378 44.2124 11.1583 44.3731 11.4255C44.5682 11.7486 44.8397 11.9101 45.1903 11.9101C45.534 11.9101 45.8038 11.746 45.998 11.4169C46.1647 11.1445 46.248 10.8215 46.248 10.4477Z"
    fill="#212121"
  />
  <path
    d="M53.9312 8.42651L52.6638 12.477H51.8389L51.3139 10.7181C51.1808 10.2791 51.0725 9.84256 50.9883 9.4095H50.9719C50.8938 9.85459 50.7855 10.2902 50.6463 10.7181L50.0886 12.477H49.2543L48.0625 8.42651H48.9879L49.4459 10.3521C49.5567 10.8075 49.6478 11.2414 49.7209 11.6521H49.7372C49.8042 11.3136 49.9151 10.8823 50.0714 10.3607L50.6463 8.42737H51.3801L51.9309 10.3194C52.0641 10.7809 52.1723 11.2251 52.2557 11.653H52.2806C52.3416 11.2363 52.4336 10.792 52.5556 10.3194L53.0471 8.42737H53.9312V8.42651Z"
    fill="#212121"
  />
  <path
    d="M58.5993 12.4769H57.6988V10.1569C57.6988 9.44201 57.4273 9.08456 56.8825 9.08456C56.6153 9.08456 56.3996 9.18251 56.232 9.37928C56.0662 9.57605 55.982 9.80805 55.982 10.0736V12.476H55.0815V9.58378C55.0815 9.22805 55.0703 8.84225 55.0488 8.42466H55.8402L55.8823 9.05792H55.9072C56.0121 8.86116 56.1684 8.69876 56.3738 8.56901C56.6178 8.41778 56.8911 8.34131 57.1901 8.34131C57.5682 8.34131 57.8827 8.46332 58.1327 8.70821C58.4438 9.00809 58.5993 9.45576 58.5993 10.0504V12.4769Z"
    fill="#212121"
  />
  <path d="M61.0832 12.4772H60.1836V6.56812H61.0832V12.4772Z" fill="#212121" />
  <path
    d="M66.3852 10.4185C66.3852 11.0414 66.2073 11.5518 65.8516 11.9522C65.4787 12.3638 64.9837 12.5692 64.3676 12.5692C63.7722 12.5692 63.2987 12.3724 62.9464 11.9771C62.5941 11.5828 62.418 11.0852 62.418 10.4855C62.418 9.85824 62.5993 9.34355 62.9636 8.94399C63.3279 8.54444 63.8177 8.34424 64.4338 8.34424C65.0293 8.34424 65.5061 8.54101 65.8688 8.9354C66.2125 9.31863 66.3852 9.81356 66.3852 10.4185ZM65.4503 10.4477C65.4503 10.0739 65.3695 9.75341 65.2088 9.48618C65.0207 9.1631 64.7509 9.00156 64.402 9.00156C64.0394 9.00156 63.7644 9.1631 63.5762 9.48618C63.4147 9.75341 63.3348 10.0791 63.3348 10.464C63.3348 10.8378 63.4156 11.1583 63.5762 11.4255C63.7713 11.7486 64.0428 11.9101 64.3934 11.9101C64.7371 11.9101 65.0061 11.746 65.2002 11.4169C65.3678 11.1445 65.4503 10.8215 65.4503 10.4477Z"
    fill="#212121"
  />
  <path
    d="M70.7433 12.4772H69.9347L69.8677 12.0107H69.8428C69.5661 12.3827 69.1717 12.5692 68.6596 12.5692C68.2772 12.5692 67.9679 12.4463 67.735 12.2023C67.5237 11.9806 67.418 11.7048 67.418 11.3774C67.418 10.8825 67.6242 10.5053 68.0392 10.244C68.4534 9.98283 69.036 9.8548 69.7861 9.86081V9.7852C69.7861 9.25161 69.506 8.98524 68.9449 8.98524C68.5453 8.98524 68.193 9.08577 67.8888 9.28512L67.7058 8.69395C68.0822 8.4611 68.547 8.34424 69.0952 8.34424C70.1539 8.34424 70.6849 8.90275 70.6849 10.0198V11.5114C70.6849 11.9161 70.7046 12.2384 70.7433 12.4772ZM69.8084 11.0852V10.4606C68.8151 10.4434 68.3185 10.7158 68.3185 11.2769C68.3185 11.4882 68.3752 11.6463 68.4912 11.752C68.6072 11.8577 68.755 11.9101 68.9311 11.9101C69.1288 11.9101 69.3135 11.8474 69.4819 11.7228C69.6512 11.5974 69.7552 11.4384 69.7938 11.2433C69.8033 11.1995 69.8084 11.1463 69.8084 11.0852Z"
    fill="#212121"
  />
  <path
    d="M75.8608 12.4772H75.0617L75.0196 11.8267H74.9947C74.7395 12.3217 74.3047 12.5691 73.6938 12.5691C73.2057 12.5691 72.7993 12.3775 72.477 11.9943C72.1548 11.6111 71.9941 11.1135 71.9941 10.5026C71.9941 9.84701 72.1686 9.316 72.5191 8.91043C72.8586 8.53236 73.2744 8.34333 73.7694 8.34333C74.3133 8.34333 74.6939 8.52635 74.9105 8.89325H74.9276V6.56812H75.829V11.3859C75.829 11.7803 75.8393 12.1438 75.8608 12.4772ZM74.9276 10.769V10.0936C74.9276 9.97676 74.9191 9.88224 74.9027 9.81007C74.852 9.59353 74.7429 9.41137 74.5771 9.26444C74.4095 9.11751 74.2076 9.04361 73.9747 9.04361C73.6388 9.04361 73.3758 9.1768 73.1825 9.44402C72.9909 9.71125 72.8938 10.0524 72.8938 10.4691C72.8938 10.8695 72.9857 11.1943 73.1705 11.4444C73.3655 11.7107 73.6284 11.8439 73.9575 11.8439C74.2531 11.8439 74.4894 11.7331 74.669 11.5105C74.8426 11.3052 74.9276 11.0577 74.9276 10.769Z"
    fill="#212121"
  />
  <path
    d="M83.561 10.4185C83.561 11.0414 83.3831 11.5518 83.0274 11.9522C82.6544 12.3638 82.1612 12.5692 81.5434 12.5692C80.9497 12.5692 80.4762 12.3724 80.1222 11.9771C79.7699 11.5828 79.5938 11.0852 79.5938 10.4855C79.5938 9.85824 79.7751 9.34355 80.1394 8.94399C80.5037 8.54444 80.9935 8.34424 81.6113 8.34424C82.205 8.34424 82.6837 8.54101 83.0445 8.9354C83.3882 9.31863 83.561 9.81356 83.561 10.4185ZM82.6278 10.4477C82.6278 10.0739 82.547 9.75341 82.3863 9.48618C82.1964 9.1631 81.9284 9.00156 81.5778 9.00156C81.2169 9.00156 80.9419 9.1631 80.752 9.48618C80.5905 9.75341 80.5106 10.0791 80.5106 10.464C80.5106 10.8378 80.5913 11.1583 80.752 11.4255C80.9471 11.7486 81.2186 11.9101 81.5692 11.9101C81.9129 11.9101 82.1836 11.746 82.3778 11.4169C82.5436 11.1445 82.6278 10.8215 82.6278 10.4477Z"
    fill="#212121"
  />
  <path
    d="M88.4031 12.4769H87.5035V10.1569C87.5035 9.44201 87.2319 9.08456 86.6863 9.08456C86.4191 9.08456 86.2034 9.18251 86.0367 9.37928C85.87 9.57605 85.7867 9.80805 85.7867 10.0736V12.476H84.8853V9.58378C84.8853 9.22805 84.875 8.84225 84.8535 8.42466H85.644L85.6861 9.05792H85.7111C85.8167 8.86116 85.9731 8.69876 86.1776 8.56901C86.4225 8.41778 86.6949 8.34131 86.9948 8.34131C87.372 8.34131 87.6865 8.46332 87.9365 8.70821C88.2484 9.00809 88.4031 9.45576 88.4031 10.0504V12.4769Z"
    fill="#212121"
  />
  <path
    d="M94.463 9.10148H93.4714V11.0692C93.4714 11.5692 93.6476 11.8193 93.9964 11.8193C94.158 11.8193 94.292 11.8055 94.3977 11.7772L94.4209 12.4603C94.2431 12.5273 94.0093 12.5608 93.7215 12.5608C93.3657 12.5608 93.0891 12.4526 92.8889 12.236C92.6878 12.0195 92.5881 11.6552 92.5881 11.1439V9.10148H91.9961V8.42697H92.5881V7.68458L93.4706 7.41821V8.42611H94.4622L94.463 9.10148Z"
    fill="#212121"
  />
  <path
    d="M99.2307 12.4772H98.3293V10.1744C98.3293 9.44832 98.0578 9.08486 97.5139 9.08486C97.0963 9.08486 96.811 9.29537 96.6546 9.71641C96.628 9.80491 96.6125 9.91318 96.6125 10.0403V12.4763H95.7129V6.56812H96.6125V9.00924H96.6297C96.9133 8.56501 97.3197 8.34333 97.8464 8.34333C98.2194 8.34333 98.5278 8.46534 98.7727 8.71022C99.0777 9.01526 99.2307 9.46894 99.2307 10.0687V12.4772Z"
    fill="#212121"
  />
  <path
    d="M104.148 10.2603C104.148 10.4218 104.136 10.5576 104.115 10.6684H101.414C101.426 11.0688 101.555 11.3739 101.805 11.5852C102.033 11.7743 102.328 11.8688 102.689 11.8688C103.089 11.8688 103.453 11.8052 103.781 11.6772L103.922 12.3027C103.538 12.4694 103.086 12.5528 102.563 12.5528C101.935 12.5528 101.441 12.368 101.084 11.9985C100.725 11.6291 100.547 11.1333 100.547 10.5112C100.547 9.90026 100.713 9.39158 101.047 8.98602C101.396 8.55296 101.868 8.33643 102.463 8.33643C103.046 8.33643 103.488 8.55296 103.787 8.98602C104.029 9.32972 104.148 9.75505 104.148 10.2603ZM103.289 10.0274C103.296 9.7602 103.236 9.52992 103.114 9.33573C102.958 9.08569 102.72 8.96024 102.398 8.96024C102.104 8.96024 101.864 9.08225 101.681 9.32714C101.532 9.52219 101.443 9.75505 101.414 10.0274H103.289Z"
    fill="#212121"
  />
</svg>
