<script lang="ts">
  import { STORES } from './stores.const';
</script>

<div class="flex gap-2 cursor-pointer">
  {#each STORES as store}
    <a target="_blank" href={store.link} title={store.alt}>
      <svelte:component this={store.svg} class="fill-dark-cream" />
    </a>
  {/each}
</div>
