<script lang="ts">
  import type { NavigationItem } from '@components/Footer/navigation.const';
  import { Typography } from '@components/ui/typography';

  export let item: NavigationItem;
</script>

<div class={`flex flex-col gap-3 ${item?.css}`}>
  <Typography
    variant="span"
    size="xl"
    class="tracking-widest uppercase text-dark-cream font-medium"
  >
    {item.title}
  </Typography>

  <div class="flex flex-col gap-1">
    {#each item.links as link}
      <a href={link.href} class="text-base font-normal text-dark-cream">
        {link.label}
      </a>
    {/each}
  </div>
</div>
