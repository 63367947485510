import AppStore from '@components/SVGs/AppStore.svelte';
import GooglePlay from '@components/SVGs/GooglePlay.svelte';
import type { SvelteComponent } from 'svelte';
import { getAppleLink, getGoogleLink } from '@constants/appStoresUrl.ts';

export type StoreItem = {
  link: string;
  svg: typeof SvelteComponent;
  alt?: string;
};

export const STORES: StoreItem[] = [
  {
    link: getAppleLink(),
    svg: AppStore,
    alt: 'App Store'
  },
  {
    link: getGoogleLink(),
    svg: GooglePlay,
    alt: 'Google Play'
  }
];
