import { getDownloadAppLink } from '@core/const/singular-links.const';

const companyUrl = import.meta.env.PUBLIC_COMPANY_BASE_URL;
const webUrl = import.meta.env.PUBLIC_WEB_BASE_URL;

export type NavigationLink = {
  label: string;
  href: string;
};

export type NavigationItem = {
  title: string;
  links: NavigationLink[];
  css?: string;
};

export const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    title: 'About us',
    css: 'order-1',
    links: [
      {
        label: 'Blog',
        href: 'https://flip.shop/blog'
      },
      {
        label: 'Contact Us',
        href: `${companyUrl}/info/contact`
      }
    ]
  },
  {
    title: 'Legal',
    css: 'order-2 lg:order-3',
    links: [
      {
        label: 'Privacy Policy',
        href: 'https://flip.shop/privacy'
      },
      {
        label: 'Terms & Conditions',
        href: 'https://flip.shop/terms'
      },
      {
        label: 'Return Policy',
        href: 'https://help.flip.shop/returns/'
      },
      {
        label: 'Community Rules',
        href: 'https://flip.shop/community-rules'
      }
    ]
  },
  {
    title: 'Review on Flip',
    css: 'order-3 lg:order-2',
    links: [
      {
        label: 'Become a Flip brand',
        href: `${webUrl}/sellwithflip`
      },
      {
        label: 'Become a Flip creator',
        href: getDownloadAppLink()
      }
    ]
  }
];
