<script lang="ts">
  import { SOCIALS } from './socials.const';
</script>

<div class="flex flex-col gap-2">
  <span class="text-dark-cream uppercase text-base font-semibold">Connect</span>

  <ul class="flex gap-3">
    {#each SOCIALS as social}
      <li class="w-8 flex justify-center items-center bg-dark-cream rounded-full h-8">
        <a href={social.link} title={social.alt}>
          <svelte:component this={social.icon} alt={social.alt} />
        </a>
      </li>
    {/each}
  </ul>
</div>
