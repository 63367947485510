<svg width="16" height="19" viewBox="0 0 16 19" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_3850_490)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.4965 4.49887C13.4968 4.49927 13.4972 4.49967 13.4975 4.50007C14.1797 4.93457 14.9931 5.18807 15.8663 5.18807V5.86857L15.8664 8.24457C14.2444 8.24457 12.7414 7.73747 11.5146 6.87687V13.0893C11.5146 16.1919 8.9319 18.716 5.7572 18.716C4.5305 18.716 3.3929 18.3379 2.4578 17.6962C2.4573 17.6957 2.4568 17.6951 2.4562 17.6946C0.972399 16.6758 0 14.9917 0 13.0887C0 9.98617 2.5827 7.46197 5.7574 7.46197C6.0208 7.46197 6.279 7.48317 6.5332 7.51687L6.5331 10.6383C6.2875 10.5632 6.0279 10.5195 5.7572 10.5195C4.3074 10.5195 3.1279 11.6724 3.1279 13.0893C3.1279 14.076 3.7007 14.9333 4.5376 15.3637C4.9025 15.5514 5.317 15.659 5.7571 15.659C7.1735 15.659 8.3289 14.5579 8.3815 13.186L8.3865 0.93457H11.5145"
    />
  </g>
  <defs>
    <clipPath id="clip0_3850_490">
      <rect width="15.8664" height="17.7814" fill="white" transform="translate(0 0.93457)" />
    </clipPath>
  </defs>
</svg>
