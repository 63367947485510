<svg width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.81507 16.9963V6.15893H0.212938V16.9963H3.81507ZM2.01399 4.67928C3.27012 4.67928 4.05196 3.84709 4.05196 2.8071C4.02855 1.74369 3.27013 0.934599 2.03782 0.934599C0.805636 0.934599 0 1.74369 0 2.8071C0 3.84709 0.781673 4.67928 1.99051 4.67928H2.01399Z"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M5.80859 16.9963H9.41069V10.9442C9.41069 10.6204 9.4341 10.2968 9.52922 10.0652C9.78962 9.41809 10.3823 8.74785 11.3774 8.74785C12.6809 8.74785 13.2023 9.74166 13.2023 11.1986V16.9963H16.8041V10.7823C16.8041 7.45355 15.027 5.90463 12.6571 5.90463C10.7138 5.90463 9.86071 6.9908 9.38673 7.73063H9.41079V6.15893H5.80869C5.85596 7.17584 5.80859 16.9963 5.80859 16.9963Z"
  />
</svg>
